<template>
  <div>
    <div id="lineChart"></div>
    <div v-if="loaded" class="buttons are-small">
      <button @click="daysToShow = xAxis.length" class="button is-ghost"
              :class="[daysToShow == xAxis.length ? 'is-primary' : '']">
        All
      </button>
      <button @click="daysToShow = 365" class="button is-ghost"
              :class="[daysToShow == 365 ? 'is-primary' : '']">
        1 year
      </button>
      <button @click="daysToShow = 183" class="button is-ghost"
              :class="[daysToShow == 183 ? 'is-primary' : '']">
        6 months
      </button>
      <button @click="daysToShow = 30" class="button is-ghost"
              :class="[daysToShow == 30 ? 'is-primary' : '']">
        1 month
      </button>
      <button @click="daysToShow = 7" class="button is-ghost"
              :class="[daysToShow == 7 ? 'is-primary' : '']">
        1 week
      </button>
    </div>
    <div v-if="!loaded">
      <progress class="progress is-small is-primary" max="100">15%</progress>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {takeRight} from 'lodash'
import d3 from 'd3'
import {format} from 'd3-format'
import bb, {line} from 'billboard.js'


const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

export default {
  data: function () {
    return {
      xAxis: [],
      amountsUSD: [],
      daysToShow: 30,
      loaded: false,
      chart: null,
    }
  },
  props: ['url'],
  created() {
    axios.get(this.url)
        .then(res => {
          return res.data
        })
        .then(data => {
          this.xAxis = data.xAxis
          this.amountsUSD = data.amountsUSD
          this.loaded = true
          this.chart = bb.generate({
            data: {
              x: "x",
              columns: [
                ['x'],
                ['USD']
              ],
              type: line(),
            },
            axis: {
              x: {
                type: "timeseries",
                tick: {
                  format: "%Y-%m-%d"
                }
              },
              y: {
                tick: {
                  format: function (y) {
                    return format("$,.2f")(y);
                  }
                }
              },
            },
            transition: {
              duration: 1000,
            },
            bindto: "#lineChart"
          });
          this.generateChart()
        })
  },
  methods: {
    generateChart() {
      this.chart.load({
        columns: [
          ['x', ...this.filteredxAxis],
          ['USD', ...this.filteredAmountsUSD]
        ],
      })
    }
  },
  computed: {
    filteredxAxis() {
      return takeRight(this.xAxis, this.daysToShow)
    },
    filteredAmountsUSD() {
      return takeRight(this.amountsUSD, this.daysToShow)
    },
  },
  watch: {
    daysToShow() {
      this.generateChart()
    }
  },
}
</script>

<style scoped>
</style>
